/*Header */
/*scroll header*/
$(window).scroll(function () {
  if ($(document).scrollTop() > 80) {
    $(".header_wrapper").addClass("scrolled");
  } else {
    $(".header_wrapper").removeClass("scrolled");
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function (event) {
  var st = $(this).scrollTop();
  if (st > lastScrollTop) {
    // downscroll code
    $(".header_wrapper").addClass("down");
  } else {
    // upscroll code
    $(".header_wrapper").removeClass("down");
  }
  lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on("click", function () {
  return false;
});

/*Open Menu*/
$(".menu_open button").on("click", function () {
  if ($(this).hasClass("is-active")) {
    $("body").css("overflow-y", "scroll");
    $(this).removeClass("is-active");
    $(".headermenu").removeClass("is-active");
    $(".header_wrapper").removeClass("openmenu");
    $(".menu li").removeClass("aos-animate");
  } else {
    $(this).addClass("is-active");
    jQuery("body").css("overflow-y", "hidden");
    $(".headermenu").addClass("is-active");
    $(".header_wrapper").addClass("openmenu");
    setTimeout(function () {
      $(".menu li").each(function (index) {
        $(this).addClass("aos-animate");
        $(this).addClass("aos-init");
      });
    }, 1000);
  }
});

/*Menu Animation*/
$(document).ready(function () {
  $(".menu > li").each(function (index) {
    $(this).attr("data-aos", "fade-up");
    $(this).attr("data-aos-duration", "600");
    $(this).addClass("aos-init");
    setTimeout(function () {
      $(".menu li").removeClass("aos-animate");
    }, 300);
    if (index == 0) {
      $(this).attr("data-aos-delay", 100);
    } else {
      $(this).attr("data-aos-delay", index * 150);
    }
  });
});

/*Gallery*/
import Swiper, { Navigation } from "swiper";
const gallery_swi = new Swiper(".gallery_swipe", {
  slidesPerView: "1",
  centeredSlides: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

/*Slider Rooms Section Homepage*/
// const rooms_swi = new Swiper(".room_swipe", {
//   slidesPerView: "3",
//   centeredSlides: false,
//   breakpoints: {
//     0: {
//       slidesPerView: "1",
//       centeredSlides: false,
//     },
//     768: {
//       slidesPerView: "2",
//       centeredSlides: false,
//     },
//     1025: {
//      slidesPerView: "3",
//      centeredSlides: false,
//    }
//   },
//   navigation: {
//    nextEl: '.swiper-button-next',
//    prevEl: '.swiper-button-prev',
//  }
// });

const subMenuBtns = document.querySelectorAll(".menu-item-has-children");
subMenuBtns.forEach((btn) => {
  btn.addEventListener("click", () => {
    btn.querySelector(".sub-menu").classList.toggle("is-active");
    btn.firstElementChild.classList.toggle("is-active");
    if (window.innerWidth < 768) {
      document.querySelector(".headermenu").classList.toggle("sub-open");
    }
  });
});

if (
  document.querySelector(".page-template-tmpl_career") &&
  document.querySelector(".wpcf7-file") &&
  document.querySelector("#file-name")
) {
  const fileInput = document.querySelector(".wpcf7-file");
  const fileNameSpan = document.getElementById("file-name");

  fileInput.addEventListener("change", (event) => {
    const fileName = event.target.value.split("\\").pop(); // Extract filename
    fileNameSpan.textContent = fileName ? fileName : "No file selected";
  });
}

// Homepage Villa Swiper
const homepageSwiper = new Swiper(".homepage-villa-slider", {
  slidesPerView: "1",
  loop: false,

  // Navigation arrows
  navigation: {
    nextEl: ".arrow-button-prev",
    prevEl: ".arrow-button-next",
  },
});

if (document.querySelector(".homepage-villa-slider") != null) {
  homepageSwiper.on("slideChange", function () {
    document.querySelector(".current-index-villas").textContent =
      homepageSwiper.activeIndex + 1;
  });
  // Update swiper amount and title
  document.querySelector(".swiper-amount-villas").textContent =
    homepageSwiper.slides.length;
}

if ($(window).width() > 768) {
  $("#slider").on("click", function () {
    if ($(".slider_cursor").hasClass("left")) {
      homepageSwiper.slidePrev();
    } else {
      homepageSwiper.slideNext();
    }
  });

  $("#slider").mousemove(function (e) {
    var relX = e.pageX - $(this).offset().left;
    var relY = e.pageY - $(this).offset().top;
    var lSide = $(this).width() / 2;

    if (e.pageX < lSide) {
      $(".slider_cursor").addClass("left");
    } else {
      $(".slider_cursor").removeClass("left");
    }

    $(".slider_cursor").css("left", relX);
    $(".slider_cursor").css("top", relY);
    $(".slider_cursor").addClass("active");
  });

  $("#slider").mouseleave(function (e) {
    $(".slider_cursor").removeClass("active");
  });
}

// Hero Swiper
const hero_swi = new Swiper(".hero_swipe", {
  slidesPerView: "1",
  centeredSlides: false,
  autoplay: {
    delay: 5000,
  },
  loop: true,
});

// Initialize homepage testimonials-swiper
const testimonial_swi = new Swiper(".testimonials-swiper", {
  slidesPerView: 1,
  spaceBetween: 50,

  breakpoints: {
    0: {
      slidesPerView: 1,
      centeredSlides: false,
    },

    768: {
      slidesPerView: 2,
      centeredSlides: false,
    },

    1025: {
      slidesPerView: 3,
      centeredSlides: false,
    },

    1280: {
      slidesPerView: 3,
      centeredSlides: false,
    },
  },
});

// Initialize suggestion swiper
const inforowSwipers = document.querySelectorAll(".inforow-swiper");

inforowSwipers.forEach((swiper) => {
  const suggestion_swiper = new Swiper(swiper, {
    slidesPerView: "1",
    grabCursor: true,
  });

  const nextArrows = swiper.querySelectorAll(".inforow-swiper-button-next");
  const prevArrows = swiper.querySelectorAll(".inforow-swiper-button-prev");

  nextArrows.forEach((arrow) => {
    arrow.addEventListener("click", () => {
      suggestion_swiper.slideNext();
    });
  });

  prevArrows.forEach((arrow) => {
    arrow.addEventListener("click", () => {
      suggestion_swiper.slidePrev();
    });
  });
});

// Initialize suggestion swiper
const villasSwipers = document.querySelectorAll(".villa-swiper");

villasSwipers.forEach((swiper) => {
  const villa_swiper = new Swiper(swiper, {
    slidesPerView: "1",
    grabCursor: true,
    loop: true,
  });

  const nextArrows = swiper.querySelectorAll(".villas-swiper-button-next");
  const prevArrows = swiper.querySelectorAll(".villas-swiper-button-prev");

  nextArrows.forEach((arrow) => {
    arrow.addEventListener("click", () => {
      villa_swiper.slideNext();
    });
  });

  prevArrows.forEach((arrow) => {
    arrow.addEventListener("click", () => {
      villa_swiper.slidePrev();
    });
  });
});
